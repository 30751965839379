import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Straaleterapi from "../../components/Straaleterapi.js"
import Knap from "../../components/Knap.js"


const StraaleterapiPage = () => (
    <LayoutComponent>
        <h1>Stråleterapi</h1>
        <p>Hos Easymed har vi en liten, men nøye utvalgt, gruppe av leverandører, som kan levere tilbehør til dosimetri, dosimeter, stråleanalyse, kvalitetskontroll (QA) og andre stråleterapeutiske instrumenter.</p>
        <h1>Våre leverandører</h1>
        <Straaleterapi />
        <Knap />
    </LayoutComponent>
)
export default StraaleterapiPage